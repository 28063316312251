@font-face {
  font-family: "Inter";
  font-weight: 300;
  font-style: normal;
  src: local("Inter"), url(assets/fonts/Inter-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-style: normal;
  src: local("Inter"), url(assets/fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-style: normal;
  src: local("Inter"), url(assets/fonts/Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  font-style: normal;
  src: local("Inter"), url(assets/fonts/Inter-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  font-style: normal;
  src: local("Inter"), url(assets/fonts/Inter-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  font-style: normal;
  src: local("Inter"), url(assets/fonts/Inter-ExtraBold.ttf) format("truetype");
}

* {
  font-family: "Inter";
  scrollbar-width: none;
  ::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}
